import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import L from 'leaflet';
import 'leaflet.markercluster';
import RangeSlider from './components/RangeSlider';
import { ClipLoader } from 'react-spinners';
import './App.css';
import { initGA, logPageView } from './utils/analytics';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppBar, CssBaseline, FormControl, useTheme } from '@mui/material';
import CustomizedInputsStyled from './components/CustomizedInputsStyled';
import { Drawer, Box, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Ace } from './types';
import MarkerCluster from './components/MarkerCluster';
import TotalAces from './components/TotalAces';

function App(): JSX.Element {
  const [acesData, setAcesData] = useState<Ace[]>([]);
  const [filteredAces, setFilteredAces] = useState<Ace[]>([]);
  const [playerNameFilter, setPlayerNameFilter] = useState('');
  const [courseFilter, setCourseFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [playerNumberFilter, setPlayerNumberFilter] = useState('');
  const [lengthFilter, setLengthFilter] = useState<number[]>([0, 600]);
  const [eventIDFilter, setEventIDFilter] = useState('');
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    initGA('G-1RM8XP9M2G');
    logPageView();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      let allData = [] as any;
      let page = 1;
      let totalPages = 1;

      try {
        while (page <= totalPages) {
          const response = await fetch(`https://api.discgolfacetracker.com/aces.json?page=${page}&per_page=100`);
          const data = await response.json();
          allData = [...allData, ...data.items];
          totalPages = data.meta.total_pages;
          page += 1;
        }
        setAcesData(allData);
        setFilteredAces(allData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchAllData();
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    // Apply filters whenever the ace data or filter values change
    let filtered = acesData;
    if (playerNameFilter) {
      filtered = filtered.filter((ace) =>
        ace.player_name.toLowerCase().includes(playerNameFilter.toLowerCase())
      );
    }
    if (courseFilter) {
      filtered = filtered.filter((ace) =>
        ace.course.toLowerCase().includes(courseFilter.toLowerCase())
      );
    }
    if (locationFilter) {
      filtered = filtered.filter((ace) =>
        ace.location.toLowerCase().includes(locationFilter.toLowerCase())
      );
    }
    if (playerNumberFilter) {
      filtered = filtered.filter((ace) =>
        ace.player_number.includes(playerNumberFilter)
      );
    }
    if (eventIDFilter) {
      filtered = filtered.filter((ace) =>
        ace.pdga_event_id.includes(eventIDFilter)
      );
    }
    filtered = filtered.filter((ace) => {
      const length = parseInt(ace.length);
      return length >= lengthFilter[0] && length <= lengthFilter[1];
    });
    setFilteredAces(filtered);
  }, [
    acesData,
    playerNameFilter,
    courseFilter,
    locationFilter,
    playerNumberFilter,
    eventIDFilter,
    lengthFilter,
  ]);

  const maxBounds: [[number, number], [number, number]] = [
    [-90, -180],
    [90, 180],
  ];

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    const width = drawerOpen ? 0 : 300;
    setDrawerOpen(!drawerOpen);
    setDrawerWidth(width);
  };

  const resetData = () => {
    setAcesData([]);
    setFilteredAces([]);
  };

  return (
    <Box className="App">
      <CssBaseline />
      <AppBar className="App-header" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer()}>
          <MenuIcon />
        </IconButton>
        <h1>Disc Golf Ace Tracker</h1>
        
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        variant="persistent"
        sx={{
          width: drawerWidth,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          })
        }}
      >
        <Box
          sx={{ overflow: 'auto' }}
          role="presentation"
        >
          <FormControl>
            <div className="filter-container length">
              <Typography variant="h6" gutterBottom>
                Ace Hole Length
              </Typography>
              <RangeSlider lengthFilter={lengthFilter} setLengthFilter={setLengthFilter} />
            </div>
            <div className="filter-container">
              <CustomizedInputsStyled
                label="Track by Location"
                variant="filled"
                value={locationFilter}
                onChange={(e) => {
                  setLocationFilter(e.target.value);
                  resetData();
                }}
              />
              <CustomizedInputsStyled
                label="Track by Player Name"
                value={playerNameFilter}
                variant="filled"
                onChange={(e) => {
                  setPlayerNameFilter(e.target.value);
                  resetData();
                }}
              />
              <CustomizedInputsStyled
                label="Track by Player Number"
                value={playerNumberFilter}
                variant="filled"
                onChange={(e) => {
                  setPlayerNumberFilter(e.target.value);
                  resetData();
                }}
              />
              <CustomizedInputsStyled
                label="Track by PDGA Event ID"
                value={eventIDFilter}
                variant="filled"
                onChange={(e) => {
                  setEventIDFilter(e.target.value);
                  resetData();
                }}
              />
              <CustomizedInputsStyled
                label="Track by Course"
                value={courseFilter}
                variant="filled"
                onChange={(e) => {
                  setCourseFilter(e.target.value);
                  resetData();
                }}
              />
            </div>
          </FormControl>
        </Box>
      </Drawer>

      <Box component="main" id="map-wrapper">
        {loading && (
          <div className="spinner-overlay">
            <ClipLoader color="#0f2843" size={200} />
          </div>
        )}
        {!loading && (
          <div className="map-container">
            <MapContainer
              center={[25, -95]}
              zoom={3}
              maxZoom={18}
              minZoom={3}
              maxBounds={maxBounds}
              zoomControl={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <MarkerCluster markers={filteredAces} />
            </MapContainer>
          </div>
        )}
      </Box>
    </Box>
  );
}

export default App;
