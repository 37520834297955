import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface CustomizedInputsStyledProps {
  label: string;
  variant: TextFieldProps['variant'];
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CssTextField = styled(TextField)({
      "& label.Mui-focused": {
      color: '#ebebeb'
    },
  '& .MuiFilledInput-root': {
    color: '#ebebeb',
    backgroundColor: '#577a96',
    '&.MuiFilledInput-underline': {
      '&:before': {
        color: '#c1c1c3',
        borderBottom: '1px solid #c1c1c3',
      },
      '&:after': {
        color: '#c1c1c3',
        borderBottom: '1px solid #c1c1c3',
      }
    }

  },
  '& .MuiInputBase-input': {
    color: '#ebebeb',
  },
  '& .MuiFormLabel-root': {
    color: '#ebebeb'
  }


});

const CustomizedInputsStyled: React.FC<CustomizedInputsStyledProps> = ({ label, variant, value, onChange }) => {
  return (
    <CssTextField
      label={label}
      variant={variant}
      value={value}
      onChange={onChange}
    />
  );
};

export default CustomizedInputsStyled;
