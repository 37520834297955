import L, { divIcon, point } from 'leaflet';
import * as React from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { Ace } from '../types';

const createClusterCustomIcon = (cluster: any) => {
  return divIcon({
    html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
    className: 'custom-marker-cluster',
    iconSize: point(33, 33, true),
  });
};

interface MarkerClusterProps {
  markers: Ace[];
}

const MarkerCluster: React.FC<MarkerClusterProps> = ({ markers }) => {
  const map = useMap();

  useEffect(() => {
    const markersLayer = L.markerClusterGroup({
      iconCreateFunction: createClusterCustomIcon,
    });

    markers.forEach((ace) => {
      if (
        ace.latitude !== undefined &&
        ace.longitude !== undefined &&
        ace.latitude !== null &&
        ace.longitude !== null
      ) {
        const marker = L.marker([ace.latitude, ace.longitude]);
        marker.bindPopup(`
          <div>
            <p><strong>Hole:</strong> ${ace.hole}</p>
            <p><strong>Length:</strong> ${ace.length}</p>
            <p><strong>Location:</strong> ${ace.location}</p>
            <p><strong>Player Name:</strong> ${ace.player_name}</p>
            <p><strong>Player Number:</strong> ${ace.player_number}</p>
            <p><strong>PDGA Event:</strong> ${ace.pdga_event_id}</p>
            <p><strong>Confidence:</strong> ${ace.location_confidence}</p>
            <p><strong>Course:</strong> ${ace.course}</p>
          </div>
        `);
        markersLayer.addLayer(marker);
      }
    });

    map.addLayer(markersLayer);

    return () => {
      map.removeLayer(markersLayer);
    };
  }, [map, markers]);

  return null;
};

export default MarkerCluster;
